<template>
	<div
		class="s-card-preview"
		:style="{ height: clientHeight }"
		v-if="fileInfo"
	>
		<a-breadcrumb class="s-a-breadcrumb">
			<a-breadcrumb-item>
				<a href="/thinkTank/modelText"> 示范文本 </a>
			</a-breadcrumb-item>
			<a-breadcrumb-item>文章详情</a-breadcrumb-item>
		</a-breadcrumb>
		<a-row :gutter="16">
			<a-col :span="18">
				<div class="article-title-content">
					<div class="atc-sub-title">
						下载量：{{ fileInfo.downloads }}
					</div>
					<div class="atc-title">
						{{ fileInfo.title }}
					</div>
				</div>
				<iframe
					:src="fileUrl"
					width="100%"
					:height="clientHeight - 122 - 80 - 60"
					:style="{
						height: clientHeight - 122 - 80,
						boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
					}"
					frameborder="1"
				>
				</iframe>
				<div class="article-footer-content">
					<a-button
						type="primary"
						@click="downloadsHandle(fileInfo._id)"
						:loading="btnLoading"
						>下载</a-button
					>
				</div>
			</a-col>
			<a-col :span="6">
				<a-card style="width: 100%" :bordered="false" :extra="null">
					<template slot="title">
						<img
							src="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/ico_rm.svg"
						/>热门
					</template>
					<div class="popular-downloads">
						<div
							class="pd-item"
							v-for="(item, index) of hotList"
							:key="index"
							@click="
								$router.push(
									`/thinkTank/modelText/preview/${item._id}`
								)
							"
						>
							<img
								src="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/ico_word.svg"
							/>
							{{ item.title }}
						</div>
					</div>
				</a-card>
			</a-col>
		</a-row>
	</div>
</template>
<script>
import {
	getModelTextById,
	updateModelTextDownloads,
	getModelTextHotList,
} from '@/api/thinkTank/modelText'
export default {
	data() {
		return {
			fileUrl: '',
			clientHeight: 500,
			fileInfo: null,
			btnLoading: false,
			hotList: [],
		}
	},
	watch: {
		$route() {
			this.initData()
		},
	},
	created() {
		this.initData()
	},
	mounted() {
		this.clientHeight = `${document.documentElement.clientHeight}`
		window.onresize = function temp() {
			this.clientHeight = `${document.documentElement.clientHeight}`
		}
	},
	methods: {
		initData() {
			let _id = this.$route.params.fileId
			getModelTextById(_id).then((res) => {
				this.fileInfo = res
				this.fileUrl = `https://view.xdocin.com/xdoc?_xdoc=${encodeURIComponent(
					res.filePath
				)}&_toolbar=false&_printable=false&_saveable=false&_pdf=false&_copyable=false`
			})
			getModelTextHotList().then((res) => {
				this.hotList = res.list
			})
		},
		downloadsHandle(fileId) {
			this.btnLoading = true
			updateModelTextDownloads(fileId)
				.then((res) => {
					this.btnLoading = false
					window.open(this.fileInfo.filePath)
				})
				.catch((err) => {
					console.log(err)
					this.btnLoading = false
				})
		},
	},
}
</script>
<style lang="less" >
.s-card-preview {
	.s-a-breadcrumb {
		margin-bottom: 16px;
	}
	.article-title-content {
		min-height: 90px;
		width: 100%;
		padding: 16px 24px;
		background: white;
		.atc-sub-title {
			color: #666666;
		}
		.atc-title {
			text-align: center;
			font-size: 24px;
			font-weight: bold;
			color: #000729;
		}
	}
	.article-footer-content {
		height: 50px;
		text-align: center;
		line-height: 50px;
		background: white;
	}
	.popular-downloads {
		.pd-item {
			display: flex;
			flex-wrap: nowrap;
			align-items: flex-start;
			cursor: pointer;
			margin-bottom: 12px;
			border-bottom: 1px solid #eeeeee;
			padding-bottom: 8px;
			img {
				width: 24px;
				width: 24px;
				margin-right: 8px;
				margin-top: 5px;
			}
			&:hover {
				color: #2140d9;
			}
			font-size: 14px;
			color: #333333;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}
</style>
